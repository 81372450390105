import React, { useEffect } from "react";
import Routes from "./routers";
import { RecoilRoot } from "recoil";
import { ASSETS } from "./asset";
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { walletConnectProvider, EIP6963Connector } from '@web3modal/wagmi'
import { WagmiConfig, configureChains, createConfig } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { mainnet, goerli } from 'wagmi/chains'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

// create store
// const store = await createStore(
//   Apdash,
//   composeWithDevTools()
// );


const projectId = process.env.REACT_APP_WEB3_WALLETCONNECT_PROJECT_ID;

// 2. Create wagmiConfig
const { chains, publicClient } = configureChains(
  [mainnet],
  [walletConnectProvider({ projectId }), publicProvider()]
)

// 3. Create modal
const metadata = {
  name: 'TrustBridgeX',
  description: 'TrustBridgeX',
  url: 'https://trustbridgex.io',
  icons: [ASSETS.main_logo_full],
  verifyUrl: "https://test.trustbridgex.io"
}

const wagmiConfig = createConfig({
  connectors: [
    new WalletConnectConnector({
      chains,
      options: {
        relayUrl: 'wss://relay.walletconnect.com',
        projectId,
        showQrModal: false,
        metadata
      }
    }),
    new EIP6963Connector({ chains }),
    new InjectedConnector({ chains, options: { shimDisconnect: true } }),
    new CoinbaseWalletConnector({ chains, options: { appName: metadata.name } })
  ],
  publicClient
})

createWeb3Modal({ wagmiConfig, projectId, chains })



function App() {
  useEffect(() => {
    // 빌드 이후 콘솔 전부 지우기
    if (process.env.NODE_ENV === "production") {
      console = window.console || {};
      console.log = function no_console() { };
      console.warn = function no_console() { };
      console.error = function () { };
    }
  }, [])

  return (
    <WagmiConfig config={wagmiConfig}>
      <RecoilRoot>
        <Routes />
      </RecoilRoot>
    </WagmiConfig>
  );
}

export default App;