import React, { useState, useEffect } from 'react';
import _data from '../../data';
import { APP, PartnerSource } from '../../asset';
import { useRecoilValue } from 'recoil';
import { appName, translatedText } from '../../recoil/recoil';

function TrustedCompany() {
    // const [trustedCompany, setTrustedCompany] = useState([]);
    const textData = useRecoilValue(translatedText);
    const usingName = useRecoilValue(appName);

    return (
        <section className="client-section ptb-100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="section-heading text-center mb-5">
                            <h2>{textData['35']()}</h2>
                            <p>{textData['36'](usingName[1])}</p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-md-12">
                        <div className="owl-carousel owl-theme clients-carousel dot-indicator">
                            {PartnerSource?.map(({ id, src }) => (
                                <div className="item single-customer" key={id}>
                                    <img src={src} alt="client logo" className="customer-logo" />
                                    {/* <img src={'https://s2.coinmarketcap.com/static/img/coins/128x128/1839.png'} alt="client logo" className="customer-logo" /> */}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TrustedCompany;