import React, { useEffect } from 'react';
import { Bounce, Fade } from 'react-reveal'; // Import 'Bounce' and 'Fade' from your desired library
import { ko } from '../../language/language';
import { APP, ASSETS } from '../../asset';
import { useRecoilValue } from 'recoil';
import { appName, language, translatedText } from '../../recoil/recoil';
import MainText from '../MainText';

function Feature(props) {
    const textData = useRecoilValue(translatedText);
    const usingName = useRecoilValue(appName);
    const lang = useRecoilValue(language);
    // You can use the useEffect hook here to replicate componentDidMount behavior
    useEffect(() => {
        /**
         * Your AJAX call can go here to fetch data and update state
         */
    }, []);

    return (
        <>
            <div id="features" className={`feature-section pt-100`} >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-9 col-lg-9">
                            <div className="section-heading text-center mb-5">
                                <Fade delay={1000} spy={lang}>
                                    {/* <h2><span className="text-warning">{APP.name}</span>을 사용해야 하는 이유</h2> */}
                                    <MainText type={'feature'} />
                                    <p>
                                        {textData['8'](usingName[0])}
                                    </p>
                                </Fade>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-md-center">
                        <div className="col-lg-4 col-md-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                                        <Bounce delay={1000} spy={lang}>
                                            <img src={ASSETS.send} className="description_img" />
                                            <div className="icon-text">
                                                <h5 className="mb-2">
                                                    {/* 전송 */}
                                                    {textData['9']()}
                                                </h5>
                                                <p>
                                                    {/* 원하는 곳 어디로는 바로 전송 가능 */}
                                                    {textData['10']()}
                                                </p>
                                            </div>
                                        </Bounce>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <Bounce delay={1200} spy={lang}>
                                        <div className="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                                            <img src={ASSETS.swap} className="description_img" />
                                            <div className="icon-text">
                                                <h5 className="mb-2">
                                                    {/* 스왑 */}
                                                    {textData['11']()}
                                                </h5>
                                                <p>
                                                    {/* 더 빠른 전송을 위해 토큰 포인트로 스왑 */}
                                                    {textData['12']()}
                                                </p>
                                            </div>
                                        </div>
                                    </Bounce>
                                </div>
                                <div className="col-12">
                                    <Bounce delay={1400} spy={lang}>
                                        <div className="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                                            <img src={ASSETS.trade} className="description_img" />
                                            <div className="icon-text">
                                                <h5 className="mb-2">
                                                    {/* 거래 */}
                                                    {textData['13']()}
                                                </h5>
                                                <p>
                                                    {/* 전 세계 모든 사람과 언제든 거래 가능 */}
                                                    {textData['14']()}
                                                </p>
                                            </div>
                                        </div>
                                    </Bounce>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-5 d-none d-sm-none d-md-block d-lg-block">
                            <Fade spy={lang}>
                                <div className="position-relative pb-md-5 py-lg-0">
                                    <img alt="placeholder" src={ASSETS.dangken_mockup_hand} className="img-center img-fluid" />
                                </div>
                            </Fade>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="row">
                                <div className="col-12">
                                    <Bounce delay={1600} spy={lang}>
                                        <div className="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                                            <img src={ASSETS.speed} className="description_img" />
                                            <div className="icon-text">
                                                <h5 className="mb-2">
                                                    {/* 속도 */}
                                                    {textData['15']()}
                                                </h5>
                                                <p>
                                                    {/* 어떤 블록체인 시스템보다 빠른 속도 */}
                                                    {textData['16']()}
                                                </p>
                                            </div>
                                        </div>
                                    </Bounce>
                                </div>
                                <div className="col-12">
                                    <Bounce delay={1800} spy={lang}>
                                        <div className="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                                            <img src={ASSETS.kyc} className="description_img" />
                                            <div className="icon-text">
                                                <h5 className="mb-2">
                                                    {/* 인증 */}
                                                    {textData['17']()}
                                                </h5>
                                                <p>
                                                    {/* 복잡한 중앙화 거래소의 인증 시스템을 없앤 간편 */}
                                                    {textData['18']()}
                                                </p>
                                            </div>
                                        </div>
                                    </Bounce>
                                </div>
                                <div className="col-12">
                                    <Bounce delay={2000} spy={lang}>
                                        <div className="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                                            <img src={ASSETS.deposit} className="description_img" />
                                            <div className="icon-text">
                                                <h5 className="mb-2">
                                                    {/* 출금 */}
                                                    {textData['19']()}
                                                </h5>
                                                <p>
                                                    {/* 언제든지 내가 원할 때 바로 현금 교환 가능 */}
                                                    {textData['20']()}
                                                </p>
                                            </div>
                                        </div>
                                    </Bounce>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
}

export default Feature;