import React from 'react'
import { useRecoilValue } from 'recoil'
import { appName, language } from '../recoil/recoil'

const MainText = ({ type }) => {

    // type = about | 

    const lang = useRecoilValue(language);
    const usingName = useRecoilValue(appName);

    const renterText = () => {
        if (type === 'about') {
            switch (lang) {
                case "ko": return (<h2 className='main_text'><span className="text-warning">{usingName[0]}</span> 은 무엇인가요?</h2>)
                case "en": return (<h2 className='main_text'>What is <span className="text-warning">{usingName[0]}</span>?</h2>)
                case "cn": return (<h2 className='main_text'><span className="text-warning">{usingName[0]}</span> 是什么?</h2>)
                case "jp": return (<h2 className='main_text'><span className="text-warning">{usingName[0]}</span> とは何ですか?</h2>)
                case "indonesia": return (<h2><span className="text-warning">{usingName[0]}</span> itu apa?</h2>)
            }
        }

        if (type === 'feature') {
            switch (lang) {
                case "ko": return (<h2 className='main_text'><span className="text-warning">{usingName[0]}</span>을 사용해야 하는 이유</h2>)
                case "en": return (<h2 className='main_text'>Reasons to Use <span className="text-warning">{usingName[0]}</span></h2>)
                case "cn": return (<h2 className='main_text'>使用 <span className="text-warning">{usingName[0]}</span> 的理由</h2>)
                case "jp": return (<h2 className='main_text'><span className="text-warning">{usingName[0]}</span> を使用する理由</h2>)
                case "indonesia": return (<h2 className='main_text'>Alasan Menggunakan <span className="text-warning">{usingName[0]}</span></h2>)
            }
        }

        if (type === 'work_process') {
            switch (lang) {
                case "ko": return (<h2 className='main_text'><span className="text-warning">{usingName[0]}</span>의 주요 기능</h2>)
                case "en": return (<h2 className='main_text'>Key Features of <span className="text-warning">{usingName[0]}</span></h2>)
                case "cn": return (<h2 className='main_text'><span className="text-warning">{usingName[0]}</span>的主要功能</h2>)
                case "jp": return (<h2 className='main_text'><span className="text-warning">{usingName[0]}</span> の主な機能</h2>)
                case "indonesia": return (<h2 className='main_text'>Fitur Utama <span className="text-warning">{usingName[0]}</span></h2>)
            }
        }
    }

    return renterText();
}

export default MainText