export const ASSETS = {
    main_mockup: require('./assets/main_mockup.webp'),
    dangken_mockup: require('./assets/dangken_mockup.png'),
    dangken_mockup_hand: require('./assets/dangken_mockup_hand.webp'),
    main_mockup1: require('./assets/dangken_mockup_main1.webp'),
    main_mockup2: require('./assets/mockup_main1.png'),
    mockup_asset: require('./assets/mockup_asset.png'),
    mockup_main: require('./assets/main.webp'),
    about_video: require('./assets/ppt.mp4'),
    send: require('./assets/send.png'),
    swap: require('./assets/swap.png'),
    trade: require('./assets/trade.png'),
    speed: require('./assets/speed.png'),
    kyc: require('./assets/kyc.png'),
    deposit: require('./assets/deposit.png'),
    box_icon: require('./assets/box_icon.png'),
    ko: require('./assets/ko.png'),
    en: require('./assets/en.png'),
    cn: require('./assets/cn.png'),
    jp: require('./assets/jp.png'),
    indonesia: require('./assets/indonesia.png'),
    my_wallet: require('./assets/my_wallet.png'),
    sell: require('./assets/sell.png'),
    buy: require('./assets/buy.png'),
    main_logo_full: require('./assets/logo.png'),
    main_logo: require('./assets/main_logo.png'),
    close: require('./assets/x-fill.png'),
    close_bold: require('./assets/x-bold.png'),
    x_logo: require('./assets/X_logo_2023_(white).png'),
    head_set: require('./assets/headset-bold (1).png'),
    down: require('./assets/down.png'),
}

export const CarouselSource = [
    {
        id: 1,
        src: require('./assets/shot1.png')
    },
    {
        id: 2,
        src: require('./assets/shot2.png')
    },
    {
        id: 3,
        src: require('./assets/shot3.png')
    },
    {
        id: 4,
        src: require('./assets/shot4.png')
    },
    {
        id: 5,
        src: require('./assets/shot5.png')
    },
    {
        id: 6,
        src: require('./assets/shot6.png')
    }
]

export const PartnerSource = [
    {
        id: 14,
        src: require('./assets/partner14.png')
    },
    {
        id: 1,
        src: require('./assets/partner1.png')
    },
    {
        id: 2,
        src: require('./assets/partner2.png')
    },
    {
        id: 4,
        src: require('./assets/partner4.png')
    },
    {
        id: 5,
        src: require('./assets/partner5.png')
    },
    {
        id: 6,
        src: require('./assets/partner6.png')
    },
    {
        id: 7,
        src: require('./assets/partner7.png')
    },
    {
        id: 8,
        src: require('./assets/partner8.png')
    },
    {
        id: 9,
        src: require('./assets/partner9.png')
    },
    {
        id: 10,
        src: require('./assets/partner10.png')
    },
    {
        id: 11,
        src: require('./assets/partner11.png')
    },
    {
        id: 12,
        src: require('./assets/partner12.png')
    },
    {
        id: 13,
        src: require('./assets/partner13.png')
    }
]

export const APP = {
    name: "Trade Bridge",
    full_name: "Trade Bridge"
}