import React, { useState } from 'react';
import { connect } from 'react-redux';
import { subscribe } from '../../actions/index';
import { useRecoilValue } from 'recoil';
import { translatedText } from '../../recoil/recoil';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom';
import { ASSETS } from '../../asset';
import { Button } from 'react-bootstrap';

function Footer(props) {
    const [email, setEmail] = useState('');
    const textData = useRecoilValue(translatedText);
    const history = useHistory()

    const handleFormValueChange = (inputName, event) => {
        if (inputName === 'email') {
            setEmail(event.target.value);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // get action
        const subscribeAction = subscribe({ email });

        // Dispatch the contact from data
        props.dispatch(subscribeAction);

        // added delay to change button text to previous
        setTimeout(() => {
            // get action again to update state
            const subscribeAction = subscribe({ email });

            // Dispatch the contact from data
            props.dispatch(subscribeAction);

            // clear form data
            setEmail('');
        }, 3000);
    };

    // TODO 20231023 : 사전예약 페이지로 이동하는 버튼 추가 (다국어 작업 예정)
    return (
        <React.Fragment>
            <footer
                className={`footer-1 gradient-bg ptb-60 ${props.withoutNewsletter && props.withoutNewsletter === true ? '' : 'footer-with-newsletter'
                    }`}
            >
                {!(props.withoutNewsletter && props.withoutNewsletter === true) && (
                    <div className="container">
                        {/* <div className="row newsletter-wrap primary-bg rounded shadow-lg p-5">
                            <div className="col-md-6 col-lg-7 mb-4 mb-md-0 mb-sm-4 mb-lg-0">
                                <div className="newsletter-content text-white">
                                    <h3 className="mb-0 text-white">{textData['37']()}</h3>
                                    <p className="mb-0">{textData['38']()}</p>
                                    <p className="mb-0">{textData['39']()}</p>
                                </div>
                            </div>
                            <div className="event_button_container col-md-6 col-lg-5"> </div>
                            <Button className='event_button' variant="success" onClick={() => history.push('/event')}>
                                    <p>{textData['40']()}</p>
                                </Button>
                            <div className="col-md-6 col-lg-5">
                                <form
                                    className="newsletter-form position-relative"
                                    method="post"
                                    onSubmit={handleSubmit}
                                >
                                    <input
                                        value={email}
                                        onChange={(e) => handleFormValueChange('email', e)}
                                        type="text"
                                        className="input-newsletter form-control"
                                        placeholder="Enter your email"
                                        name="email"
                                        required=""
                                    />
                                    <button type="submit" className="disabled">
                                        <i className="fas fa-paper-plane"></i>
                                    </button>
                                </form>
                            </div>
                        </div> */}
                    </div>
                )}

                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
                            <a href="/#" className="navbar-brand mb-2">
                                <img src={ASSETS.main_logo_full} alt="logo" style={{ width: "150px" }} />
                            </a>
                            <br />
                            <p>
                                {/* Dynamically re-engineer high standards in functiona with alternative paradigms. Conveniently
                                monetize resource maximizing initiatives. */}
                                Copyright 2023. TeamMAPA. All rights reserved.
                            </p>
                            {/* <div className="list-inline social-list-default background-color social-hover-2 mt-2">
                                <li className="list-inline-item">
                                    <a className="twitter" href="/#">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a className="youtube" href="/#">
                                        <i className="fab fa-youtube"></i>
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a className="linkedin" href="/#">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a className="dribbble" href="/#">
                                        <i className="fab fa-dribbble"></i>
                                    </a>
                                </li>
                            </div> */}
                        </div>
                        <div className="col-md-12 col-lg-8">
                            <div className="row mt-0">
                                {/* <div className="col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                                    <h6 className="text-uppercase">Resources</h6>
                                    <ul>
                                        <li>
                                            <a href="/#">Help</a>
                                        </li>
                                        <li>
                                            <a href="/#">Events</a>
                                        </li>
                                        <li>
                                            <a href="/#">Live sessions</a>
                                        </li>
                                        <li>
                                            <a href="/#">Open source</a>
                                        </li>
                                        <li>
                                            <a href="/#">Documentation</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                                    <h6 className="text-uppercase">Products</h6>
                                    <ul>
                                        <li>
                                            <a href="/#">Pricing</a>
                                        </li>
                                        <li>
                                            <a href="/#">Navigation</a>
                                        </li>
                                        <li>
                                            <a href="/#">AI Studio</a>
                                        </li>
                                        <li>
                                            <a href="/#">Page Speed </a>
                                        </li>
                                        <li>
                                            <a href="/#">Performance</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                                    <h6 className="text-uppercase">Company</h6>
                                    <ul>
                                        <li>
                                            <a href="/#">About Us</a>
                                        </li>
                                        <li>
                                            <a href="/#">Careers</a>
                                        </li>
                                        <li>
                                            <a href="/#">Customers</a>
                                        </li>
                                        <li>
                                            <a href="/#">Community</a>
                                        </li>
                                        <li>
                                            <a href="/#">Our Team</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3">
                                    <h6 className="text-uppercase">Support</h6>
                                    <ul>
                                        <li>
                                            <a href="/#">FAQ</a>
                                        </li>
                                        <li>
                                            <a href="/#">Sells</a>
                                        </li>
                                        <li>
                                            <a href="/#">Contact Support</a>
                                        </li>
                                        <li>
                                            <a href="/#">Network Status</a>
                                        </li>
                                        <li>
                                            <a href="/#">Product Services</a>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="footer-bottom py-3 gray-light-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-7">
                            <div className="copyright-wrap small-text">
                                <p className="mb-0">&copy; Team mapa</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5">
                            {/* <div className="terms-policy-wrap text-lg-right text-md-right text-left">
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            <a className="small-text" href="/#">
                                Terms
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a className="small-text" href="/#">
                                Security
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a className="small-text" href="/#">
                                Privacy Policy
                            </a>
                        </li>
                    </ul>
                </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Footer