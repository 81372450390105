import React, { useEffect } from 'react';
import Fade from 'react-reveal/Fade'; // Import 'Fade' from your desired library
import { APP, ASSETS } from '../../asset'; // Import your constants as needed
import { useRecoilValue } from 'recoil';
import { appName, translatedText } from '../../recoil/recoil';
import MainText from '../MainText';

function About() {
    const textData = useRecoilValue(translatedText);
    const usingName = useRecoilValue(appName);

    return (
        <>
            <div className="overflow-hidden">
                <section id="about" className="about-us ptb-100 background-shape-img position-relative">
                    <div className="animated-shape-wrap">
                        <div className="animated-shape-item"></div>
                        <div className="animated-shape-item"></div>
                        <div className="animated-shape-item"></div>
                        <div className="animated-shape-item"></div>
                        <div className="animated-shape-item"></div>
                    </div>
                    <div className="container">
                        <div className="row align-items-center justify-content-lg-between justify-content-md-center justify-content-sm-center">
                            <div className="col-md-12 col-lg-6 mb-5 mb-md-5 mb-sm-5 mb-lg-0">
                                <div className="about-content-left">
                                    <Fade left>
                                        <MainText type={'about'} />
                                        {/* <p>{APP.full_name}은 기존의 거래소를 통한 암호화폐 현금화, 이종토큰화 방식을 유저간 거래를 통해 간편하게, 스마트 컨트랙트를 통해서 안전하게 거래하도록 전환시킨 암호화폐 개인거래 플랫폼입니다.</p> */}
                                        <p>{textData['6'](usingName[1])}</p>
                                    </Fade>
                                    {/* Additional content if needed */}
                                </div>
                            </div>
                            <div className="col-sm-5 col-md-5 col-lg-4">
                                <Fade bottom>
                                    <div className="about-content-right">
                                        <img src={ASSETS.main_mockup} alt="about us" className="img-fluid" />
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default About;